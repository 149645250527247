import React, {Component} from "react";
import PropTypes from "prop-types";
import {Redirect} from "react-router-dom";
import R from "ramda";
import {
    Tabs,
    Badge,
    Button,
    Markdown,
    Modal
} from "@simpleryo/collinson-uikit";
import {storageUtils} from "@simpleryo/syw-utils";
import {connectApplicationSteps} from "@simpleryo/syw-application";
import {stepStatusMap} from "../../constants/status";
import ApplicationCards from "./ApplicationCards";
import DataSource from "./DataSource";
import * as Routes from "../../constants/routes";
import {isMobile} from "../../utils/functions";

const AppTab = ({status, stepNum, label}) => {
    let badgeStatus;
    let badgeIcon;
    if (status === stepStatusMap.COMPLETED) {
        badgeStatus = "completed";
        badgeIcon = "icon-check";
    } else if (status === stepStatusMap.ERROR) {
        badgeStatus = "error";
    }
    return (
        <div>
            <Badge type="status" status={badgeStatus} icon={badgeIcon} size={28}>
                {status !== stepStatusMap.COMPLETED && stepNum}
            </Badge>
            <span>{label}</span>
        </div>
    );
};

AppTab.propTypes = {
    status: PropTypes.string.isRequired,
    stepNum: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
};

class ApplicationSteps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
            submitted: false,
            isLoading: false
        };
    }

    static getDerivedStateFromProps(props, state) {
        const {
            injectedProps: {submit = {}}
        } = props;
        if (R.not(state.submitted) && submit.success === "success") {
            return {
                submitted: true
            };
        }
        return null;
    }

    onNextStep = () => {
        const {
            injectedProps: {
                activeStep,
                stepsList,
                steps,
                handleNextClick,
                handleSave
            }
        } = this.props;
        const activeStepName = stepsList[activeStep - 1];
        const {cardsList} = steps[activeStepName];
        handleSave(cardsList);
        handleNextClick();
    };

    onSubmit = () => {
        const {
            injectedProps: {handleSubmit}
        } = this.props;

        this.setState({isLoading: true});
        handleSubmit();
    };

    onBeforeSubmit = () => {
        const {
            injectedProps: {activeStep, stepsList, steps, isSubmittable, handleSave}
        } = this.props;
        const activeStepName = stepsList[activeStep - 1];
        const {cardsList} = steps[activeStepName];
        const isSave = handleSave(cardsList);
        if (isSubmittable && R.not(isSave)) {
            this.setState({isVisible: true});
        }
    };

    closeApplySubmitConfirmModal = () => {
        this.setState({isVisible: false});
    };

    checkShowSubmitBtn = () => {
        const {
            injectedProps: {steps, stepsList}
        } = this.props;

        return R.compose(
            R.isEmpty,
            R.filter(name => steps[name].status !== stepStatusMap.COMPLETED)
        )(R.dropLast(1, stepsList));
    };

    render() {
        const {
            location: {search},
            injectedProps: {
                stepsList,
                steps,
                activeStep,
                submit,
                handleChange,
                handlePrevClick,
                save
            }
        } = this.props;
        if (this.state.submitted) {
            storageUtils.setSessionStorage("Auth", "");
            storageUtils.setSessionStorage("Token", "");
            return (
                <Redirect
                    to={{
                        pathname: Routes.DOCUMENTS,
                        search,
                        state: {submit}
                    }}
                />
            );
        }
        const isLastStep = stepsList.length === parseInt(activeStep, 10);
        let isSavedLastStep = false;
        if (R.not(R.isEmpty(stepsList))) {
            const lastStepsName = R.last(stepsList);
            const savedCardsList = save.cardsList || [];
            isSavedLastStep = R.equals(
                savedCardsList,
                steps[lastStepsName].cardsList
            );
        }
        const isLastStepSaveSuccess = isSavedLastStep && save.success === "success";
        const showSubmitBtn = this.checkShowSubmitBtn();

        return (
            <div className="application-steps">
                <Tabs
                    className="dark-grey"
                    onChange={handleChange}
                    activeKey={activeStep}
                >
                    {R.map(name => {
                        const step = steps[name];
                        return (
                            <Tabs.TabPane
                                key={step.step}
                                tab={
                                    <AppTab
                                        status={step.status}
                                        stepNum={step.step}
                                        label={step.label}
                                    />
                                }
                            >
                                <div className="container">
                                    <div className="text-center margin-top-30 margin-bottom-30">
                                        <h4>{step.headline}</h4>
                                        <Markdown text={step.description}/>
                                    </div>

                                    <div className="container__inner-wrapper">
                                        <ApplicationCards cardsList={step.cardsList}/>
                                    </div>
                                </div>
                            </Tabs.TabPane>
                        );
                    })(stepsList)}
                </Tabs>

                <div className="container margin-top-50">
                    <div className="container__inner-wrapper clearfix">
                        {activeStep !== "1" && (
                            <Button
                                size={isMobile() ? "small" : "default"}
                                className="application-steps__prev"
                                onClick={handlePrevClick}
                            >
                                PREVIOUS
                            </Button>
                        )}

                        {isLastStepSaveSuccess && (
                            <Button
                                size={isMobile() ? "small" : "default"}
                                className="application-steps__next"
                                type="primary"
                                disabled={R.not(showSubmitBtn)}
                                onClick={showSubmitBtn ? this.onBeforeSubmit : R.F}
                            >
                                SUBMIT
                            </Button>
                        )}
                        {isLastStep &&
                        !isLastStepSaveSuccess && (
                            <div>
                                <Button
                                    size={isMobile() ? "small" : "default"}
                                    className="application-steps__next"
                                    type="primary"
                                    disabled={R.not(showSubmitBtn)}
                                    onClick={showSubmitBtn ? this.onBeforeSubmit : R.F}
                                >
                                    {stepsList.length == 1 ? "Check the Form before Submitting" : "CHECK FORM"}
                                </Button>
                                <div style={{
                                    float: "right",
                                    fontSize: "14px",
                                    marginTop: "6px",
                                    textAlign: "left",
                                    width: isMobile() ? "280px" : "406px",
                                    fontWeight: "bold"
                                }}>
                                    {stepsList.length == 1 ? "Click here to check if you are ready to submit. If you don’t see the “Submit” button, please check for missing information or errors on the above form." : ""}
                                </div>
                            </div>
                        )}
                        {!isLastStep && (
                            <Button
                                size={isMobile() ? "small" : "default"}
                                className="application-steps__next"
                                type="primary"
                                onClick={this.onNextStep}
                            >
                                NEXT
                            </Button>
                        )}
                    </div>
                </div>

                <Modal
                    visible={this.state.isVisible}
                    onCancel={this.closeApplySubmitConfirmModal}
                    footer={null}
                >
                    <div className="submit-confirm">
                        <h4 className="text-center">
                            <b>Submit Confirmation</b>
                        </h4>

                        <p className="text-center margin-top-100 margin-bottom-80">
                            Thank you for choosing Collinson. This Account Application Form must be read together with
                            our Terms and Conditions on our website. If we accept your application for an Account, the
                            agreement between us and you (the “Account Holder”) will comprise this Account Application
                            Form and the Terms and Conditions (“Agreement”).
                        </p>

                        <div className="text-center margin-bottom-80">
                            <Button
                                size={isMobile() ? "small" : "default"}
                                type="primary"
                                onClick={this.onSubmit}
                                loading={this.state.isLoading}
                            >
                                CONFIRM
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

ApplicationSteps.propTypes = {
    location: PropTypes.object.isRequired,
    injectedProps: PropTypes.shape({
        stepsList: PropTypes.array,
        steps: PropTypes.object,
        activeStep: PropTypes.string,
        save: PropTypes.object,
        submit: PropTypes.object,
        isSubmittable: PropTypes.bool,
        handleChange: PropTypes.func,
        handlePrevClick: PropTypes.func,
        handleNextClick: PropTypes.func,
        handleSave: PropTypes.func,
        handleSubmit: PropTypes.func
    })
};

export default connectApplicationSteps(DataSource)(ApplicationSteps);
