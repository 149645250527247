import R from "ramda";

const DIVIDE_MARK = "-";
const DEFAULT_PREFIX = "Collinson";

const convertToAppListFromContentful = list =>
  R.compose(
    R.reduce((acc, cur) => {
      const { name } = cur;
      const perfix = R.compose(
        R.head,
        R.split(DIVIDE_MARK),
        str =>
          str.indexOf(DIVIDE_MARK) > -1
            ? str
            : `${DEFAULT_PREFIX}${DIVIDE_MARK}${str}`
      )(name);
      return {
        ...acc,
        [perfix]: [...(acc[perfix] || []), cur]
      };
    }, {}),
    R.map(item => {
      const { fields } = item;
      return R.pick(["id", "name"], fields);
    })
  )(list);

export default convertToAppListFromContentful;
