import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
// import Header from "../components/Header";

const MinimalLayout = props => {
  const { children, config = {} } = props;
  const { className } = config;
  return (
    <div className={classNames("container-fluid", className)}>
      {/* <Header /> */}
      <main>{children}</main>
    </div>
  );
};

MinimalLayout.propTypes = {
  children: PropTypes.any.isRequired,
  config: PropTypes.object
};

export default MinimalLayout;
