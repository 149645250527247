import React, { Component } from "react";
import PropTypes from "prop-types";
import { connectEditableTabsGroups } from "@simpleryo/syw-application";
import classNames from "classnames";
import ApplicationFields from "../ApplicationFields";

class EditableTabsGroups extends Component {
  render() {
    const {
      injectedProps: { group }
    } = this.props;
    return (
      <div
        className={classNames("application-group application-group--editable")}
      >
        <div className="row">
          <ApplicationFields fieldsList={group.fieldsList} />
        </div>
      </div>
    );
  }
}

EditableTabsGroups.propTypes = {
  injectedProps: PropTypes.shape({
    group: PropTypes.object.isRequired
  })
};

export default connectEditableTabsGroups(EditableTabsGroups);
