import React, {Component, Fragment} from "react";
import {Redirect} from "react-router-dom";

export default class IframeIndex extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.location = "http://app.kyconline.co.nz";
    }

    render() {
        return (<section></section>);
    }
}
