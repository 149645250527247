import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, IntlProvider, addLocaleData } from "react-intl";
import enLocaleData from "react-intl/locale-data/en";
import zhLocaleData from "react-intl/locale-data/zh";
import { checkDebugMode } from "../utils/functions";

// TODO Currently, only 2 locale are supported.
// If there are more, the locale data should be added dynamiclly,
// see the doc: https://github.com/yahoo/react-intl/wiki#locale-data-in-browsers
addLocaleData(enLocaleData);
addLocaleData(zhLocaleData);

// React-intl does not expose formatMessage API without React.
// check detail discussion here: https://github.com/yahoo/react-intl/issues/416
let instance;
class Intl {
  // Singleton
  constructor() {
    if (!instance) {
      const intlProvider = new IntlProvider(window.SY_LOCALE, {});
      const { intl } = intlProvider.getChildContext();
      instance = intl;
    }
    return instance;
  }
}
/**
 * [function for formatting message with id]
 * @param  {String} id     [message id]
 * @param  {Object} values [variables in message]
 * @return {String}        [formatted message]
 */
export const formatMsg = (id, values) => {
  const formattedMsg = new Intl().formatMessage({ id }, values);
  if (checkDebugMode() === "localeKey") {
    return `(${id})\n${formattedMsg}`;
  }
  return formattedMsg;
};

const Message = ({ id, values }) => {
  let props = { id, values };
  if (checkDebugMode() === "localeKey") {
    const { messages } = window.SY_LOCALE;
    props = {
      id: "N/A",
      defaultMessage: `(${id})${messages[id] || ""}`,
      values
    };
  }
  return <FormattedMessage {...props} />;
};

Message.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired
};

export default Message;
