import React, {Component} from "react";
import PropTypes from "prop-types";
import R from "ramda";
import {connect} from "react-redux";
import {
    Form,
    Field,
    FIELD_TYPE,
    validateRules,
    formActionCreators
} from "@simpleryo/syw-form";
import {Button, CopyToClipboard, notification, Input} from "@simpleryo/syw-uikit";
import FieldTheme from "./FieldTheme";
import {fetchStatusMap} from "../constants/status";
import {APPLICATION_SIGNATURE} from "../constants/routes";
import DataSource from "../components/Application/DataSource";

const FORM_NAME_PREFIX = "signatureEmail";

class SharedLinkContainer extends Component {
    constructor(props) {
        super(props);
        const {applyId, field} = this.props;
        this.state = {
            field: field,
            applyId: applyId,
            token: undefined,
            error: undefined,
            fetchStatus: fetchStatusMap.LOADED,
            emailStatus: fetchStatusMap.LOADED,
            isCopied: false,
            email: ""
        };
    };

    getExternalSignatureUrl = () => {
        const {token, applyId, field} = this.state;
        return `${window.location.origin}${R.replace(
            /:applyId/g,
            applyId,
            APPLICATION_SIGNATURE
        )}?fieldId=${field.id}&fieldName=${
            field.name
            }&token=${token}`;
    };

    onFetchToken = () => {
        this.setState({fetchStatus: fetchStatusMap.LOADING});
        const {applyId} = this.props;
        DataSource.getExternalToken(applyId).then(
            res => {
                const {code, content, msg} = res.data;
                if (code === 0) {
                    this.setState({fetchStatus: fetchStatusMap.LOADED, token: content});
                } else {
                    this.setState({
                        fetchStatus: fetchStatusMap.LOADED,
                        error: msg || "Get Token Fail"
                    });
                }
            },
            () => {
                this.setState({
                    fetchStatus: fetchStatusMap.LOADED,
                    error: "Get Token Fail"
                });
            }
        );
    };

    onCopy = () => {
        this.setState({
            isCopied: true
        });
    };

    handleChange = event => {
        const {
            target: { value }
        } = event;
        this.setState({ email: value });
    };

    sendSignEmail = () => {
        this.setState({ emailStatus: fetchStatusMap.LOADING });
        const {
            applyId,
            email
        } = this.state;
        const signUrl = this.getExternalSignatureUrl();
        DataSource.sendSignatureEmail({
            applyId,
            emailAddress: email,
            content: signUrl
        }).then(
            res => {
                const {code} = res.data;
                if (code === 0) {
                    this.setState({emailStatus: fetchStatusMap.LOADED, error: "Send success 已发送成功"});
                } else {
                    this.setState({
                        emailStatus: fetchStatusMap.LOADED,
                        error: "Send failed, please check email address 发送失败，请检查邮件地址"
                    });
                }
            },
            () => {
                this.setState({
                    error: "Send failed, please check email address 发送失败，请检查邮件地址"
                });
            }
        );
    };

    render() {
        const {token, field, fetchStatus, error, isCopied, emailStatus} = this.state;
        const copyText = this.getExternalSignatureUrl();
        return (
            <FieldTheme
                id={`${FORM_NAME_PREFIX}${field.name}`}
                description="
                You can generate a signature link for sending and signing. To confirm the signature after submitting, **please refresh this page**. &nbsp;&nbsp;&nbsp;
                生成签名链接用于发送给客户签字，确认签字提交后请**刷新本页面**。
                "
            >
                {!token && <Button
                    key="fetch-token-btn"
                    loading={fetchStatus === fetchStatusMap.LOADING}
                    onClick={this.onFetchToken}
                    size="small"
                >
                    {fetchStatus === fetchStatusMap.LOADING
                        ? "Generating 生成中"
                        : "Generate the Link 生成链接"}
                </Button>}
                {token && <div id={`${FORM_NAME_PREFIX}${field.name}`} className={"form-group"}>
                    <div className="signature-shared-link">
                        <p className="bold">
                            Copy link to share 复制链接分享
                        </p>
                        <p className="small">{copyText}</p>
                        <p>
                            <CopyToClipboard
                                text={copyText}
                                linkText="Copy Link 复制链接"
                                onCopy={this.onCopy}
                            />
                        </p>
                        {isCopied && <p class="small" style={{color: "red"}}>Successfully copied 已复制成功</p>}
                        <hr/>
                        <div className="row">
                            <div className="col-xs-12 input-cta">
                                <p className="bold">
                                    Or send the link to a email 或 发送签名链接到邮箱
                                </p>
                                <p>
                                    <Input
                                        placeholder="E-mail address 请输入邮箱"
                                        value={this.state.email || undefined}
                                        onChange={this.handleChange}
                                    />
                                    <Button
                                        key="sendEmail"
                                        type="primary"
                                        size="small"
                                        loading={emailStatus === fetchStatusMap.LOADING}
                                        style={{marginTop: "10px"}}
                                        onClick={this.sendSignEmail}
                                    >
                                      {emailStatus === fetchStatusMap.LOADING
                                          ? "Sending 发送中"
                                          : "Send 发送"}
                                    </Button>
                                </p>
                            </div>
                        </div>
                    </div>
                    {error && <p class="small" style={{color: "red"}}>{error}</p>}
                </div>
                }
            </FieldTheme>
        );
    }
}

SharedLinkContainer.propTypes = {
    field: PropTypes.object.isRequired,
    applyId: PropTypes.number.isRequired,
    token: PropTypes.string,
    fetchStatus: PropTypes.string,
    error: PropTypes.string,
    emailForm: PropTypes.object
};

export default connect()(
    SharedLinkContainer
);

