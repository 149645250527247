import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import R from "ramda";
import queryString from "query-string";
import { Button, Alert } from "@simpleryo/collinson-uikit";
import { APPLICATION } from "../../constants/routes";
import DataSource from "../Application/DataSource";

class Landing extends Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { applyName }
      },
      location: { state = {} }
    } = props;
    this.state = {
      applyName: state.applyName || applyName,
      isLoading: false,
      error: undefined,
      applyId: undefined
    };
  }

  createApp = () => {
    this.setState({ isLoading: true });
    DataSource.createApplication(this.state.applyName).then(
      res => {
        const { code, content, msg } = res;
        if (code === 0) {
          this.setState({ isLoading: false, applyId: content.applyId });
        } else {
          this.setState({
            isLoading: false,
            error: msg || "Create Applications Fail"
          });
        }
      },
      () => {
        this.setState({
          isLoading: false,
          error: "Create Applications Fail"
        });
      }
    );
  };

  render() {
    const {
      location: { search }
    } = this.props;
    const { isLoading, error, applyName, applyId } = this.state;
    if (applyId) {
      const hideHeader = R.equals(
        applyName.toLowerCase(),
        "ccfl-investordetails"
      );
      const appSearch = hideHeader
        ? R.compose(
            queryString.stringify,
            R.mergeDeepLeft({ hide_header: hideHeader }),
            queryString.parse
          )(search)
        : search;
      return (
        <Redirect
          to={`${APPLICATION}/${applyId}/1?${appSearch.substring(
            appSearch.indexOf("?") + 1
          )}`}
        />
      );
    }
    return (
      <div className="row">
        <div className="text-center margin-top-20">
          <h5>{applyName}</h5>
          <h3>Application Form</h3>
        </div>
        <hr />

        <div className="text-center">
          <Button
            htmlType="button"
            onClick={this.createApp}
            loading={isLoading}
          >
            Click to Apply
          </Button>
        </div>

        {error && (
          <div className="col-xs-12">
            <Alert showIcon type="error" message={error} />
          </div>
        )}
      </div>
    );
  }
}

Landing.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default Landing;
