import React from "react";
import errorImage from "../../assets/imgs/icons/errorimage.svg";

const NotFoundPage = () => (
  <div className="row">
    <div className="col-xs-12 text-center">
      <h1>404</h1>
      <img src={errorImage} alt="Page not found" />
      <p>This is not the web page you are looking for.</p>
    </div>
  </div>
);

export default NotFoundPage;
