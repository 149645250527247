import { scroller, animateScroll } from "react-scroll";
import getScroll from "antd/es/_util/getScroll";
import R from "ramda";

export const getTargetRect = target =>
  target !== window
    ? target.getBoundingClientRect()
    : { top: 0, left: 0, bottom: 0 };

export const getOffset = (element, target) => {
  const elemRect = element.getBoundingClientRect();
  const targetRect = getTargetRect(target);

  const scrollTop = getScroll(target, true);
  const scrollLeft = getScroll(target, false);

  const docElem = window.document.body;
  const clientTop = docElem.clientTop || 0;
  const clientLeft = docElem.clientLeft || 0;

  return {
    top: elemRect.top - targetRect.top + scrollTop - clientTop,
    left: elemRect.left - targetRect.left + scrollLeft - clientLeft,
    width: elemRect.width,
    height: elemRect.height
  };
};

const defaultScrollOptions = {
  duration: 500,
  smooth: true
};

export const scrollTo = (targetElementId, scrollProps = {}) => {
  if (!targetElementId) {
    return;
  }
  scroller.scrollTo(targetElementId, {
    ...defaultScrollOptions,
    ...scrollProps
  });
};

export const scrollToPosition = (position, options = {}) => {
  if (
    R.isNil(position) ||
    R.isEmpty(position) ||
    R.not(R.is(Number, position)) ||
    R.lte(position, 0)
  ) {
    return;
  }
  animateScroll.scrollTo(position, {
    ...defaultScrollOptions,
    ...options
  });
};

export const getWinPosition = () => {
  // Reference: https://stackoverflow.com/questions/3464876/javascript-get-window-x-y-position-for-scroll
  const doc = document.documentElement;
  const left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
  const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
  return {
    left,
    top
  };
};
