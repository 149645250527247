import React, {Component} from "react";
import {connect} from "react-redux";
import {ImageBanner, CrossCenterContent} from "@simpleryo/syw-uikit";
import signatureSVG from "../../assets/imgs/signature.svg";

class SignatureSuccess extends Component {
    render() {
        return (
            <div className="row success-page" style={{textAlign:"center"}}>
                <div className="banner__wrapper">
                    <ImageBanner customClass="bg--white">
                        <div className="container">
                            <div>
                                <CrossCenterContent>
                                    <img
                                        className="sign-img margin-top-50 margin-bottom-50"
                                        src={signatureSVG}
                                        alt="signature"
                                    />
                                    <h5 className="black section-name">
                                        The signature has been completed
                                    </h5>
                                    <h5 className="black section-name">
                                        签名已经完成
                                    </h5>
                                    <p className="black text-center">
                                        Each link only supports one signature. If you want to re-sign, please contact us to regenerate
                                        the link
                                    </p>
                                    <p className="black text-center">
                                        每个链接仅支持一次签名，若想重新签名请联系我们为您重新生成新的链接
                                    </p>
                                </CrossCenterContent>
                            </div>
                        </div>
                    </ImageBanner>
                </div>
            </div>
        );
    }
}

export default connect()(
    SignatureSuccess
);
