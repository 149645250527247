import React, { Component } from "react";
import PropTypes from "prop-types";
import R from "ramda";
import debounce from "lodash/debounce";
import getScroll from "antd/es/_util/getScroll";
import MinimalLayout from "./Minimal";
import { getCurrentScrollTop, scrollToTop } from "../utils/functions";
import { getOffset, getTargetRect } from "../utils/animations";

export const LayoutName = {
  MINIMAL: "minimal"
};

const LayoutTemplate = {
  [LayoutName.MINIMAL]: MinimalLayout
};

class LayoutContainer extends Component {
  constructor(props) {
    super(props);
    const { history } = props;
    this.state = {
      sticky: false
    };
    this.debounceScroll = debounce(this.handleScroll, 100, {
      leading: true,
      trailing: true
    });
    this.unlistenHistory = history.listen(location => {
      if (
        R.equals(R.pathOr(false, ["state", "holdPosition"], location), false)
      ) {
        scrollToTop();
      }
    });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.debounceScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.debounceScroll);
    this.unlistenHistory();
  }

  handleScroll = () => {
    // For sticky tabs: Add `sticky-tabs` to the antd/Tabs as extra className
    const stickyTabs = document.getElementsByClassName("sticky-tabs");
    // For drop down menu(display by clicking the avatar) in the header
    const dropdownMenu = document.getElementsByClassName("dropdown-menu");

    const isNotDesktop = window.innerWidth <= 991;
    if (stickyTabs && stickyTabs.length > 0 && isNotDesktop) {
      // affix the `sticky-tabs` when scrolling on mobile/ tablet
      this.affixTabsToTop();
    }

    if (
      dropdownMenu &&
      dropdownMenu.length > 0 &&
      dropdownMenu[0].className.indexOf("ant-popover-hidden") < 0
    ) {
      // hide the `dropdown-menu` when scrolling if it's opened
      dropdownMenu[0].classList.add("ant-popover-hidden");
    }

    const isSticky = getCurrentScrollTop() !== 0;
    this.setState({ sticky: isSticky });
  };

  affixTabsToTop = () => {
    const affixNode = document.getElementsByClassName("ant-tabs-bar")[0];
    const fixedNode = document.getElementsByClassName(
      "ant-tabs-nav-container"
    )[0];
    const contentNode = document.getElementsByClassName("ant-tabs-content")[0];
    const isMobile = window.innerWidth <= 767;
    const offsetTop = isMobile ? 60 : 93;

    const scrollTop = getScroll(window, true);
    const affixNodeOffset = getOffset(affixNode, window);
    const contentNodeOffset = getOffset(contentNode, window);
    const targetRect = getTargetRect(window);

    const { width } = affixNodeOffset;
    const height = affixNode.offsetHeight;
    const top = targetRect.top + offsetTop;
    const left = targetRect.left + affixNodeOffset.left;

    let affixNodeStyle = "";
    let fixedNodeStyle = "";

    if (scrollTop > affixNodeOffset.top - offsetTop) {
      affixNodeStyle = `width: ${width}px; height: ${height}px;`;
      fixedNodeStyle = `width: ${width}px; position: fixed; top: ${top}px; left: ${left}px; background: #FFF; z-index: 999;`;
    }
    if (
      scrollTop >
      contentNodeOffset.top + contentNodeOffset.height - 400 - offsetTop
    ) {
      affixNodeStyle = "";
      fixedNodeStyle = "";
    }
    affixNode.setAttribute("style", affixNodeStyle);
    fixedNode.setAttribute("style", fixedNodeStyle);
  };

  render() {
    const { children, name, config, history, match } = this.props;
    const Template = LayoutTemplate[name];
    return (
      <Template
        history={history}
        match={match}
        config={config}
        sticky={this.state.sticky}
      >
        {children}
      </Template>
    );
  }
}

LayoutContainer.propTypes = {
  name: PropTypes.string.isRequired,
  config: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired
};

const generateLayout = (name, ViewComponent, config) => props => (
  <LayoutContainer name={name} config={config} {...props}>
    <ViewComponent {...props} />
  </LayoutContainer>
);

export default generateLayout;
