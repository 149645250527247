import createHistory from "history/createBrowserHistory";

const history = createHistory();

export const goBack = defaultURL => {
  const currentPage = window.location.href;
  history.goBack();
  setTimeout(() => {
    if (window.location.href === currentPage && defaultURL) {
      history.push(defaultURL);
    }
  }, 100);
};

export default history;
