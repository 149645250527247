import React, {Component, Fragment} from "react";
import {Spin} from "@simpleryo/collinson-uikit";

export default class IndexPage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return (
            <div className="row">
                <h1 className="text-center"></h1>
                <hr/>
                <Spin wrapperClassName="col-xs-12" spinning={false}>
                    <Fragment>
                        <a href="/collinson">
                            <h5>
                                COLLINSON FOREX LIMITED
                            </h5>
                        </a>
                        <hr/>
                    </Fragment>

                    <Fragment>
                        <a href="/goldenmountain">
                            <h5>
                                GOLDEN MOUNTAIN FINANCE GROUP
                            </h5>
                        </a>
                        <hr/>
                    </Fragment>

                    <Fragment>
                        <a href="/kingsida">
                            <h5>
                                KINGSIDA TRADING LIMITED
                            </h5>
                        </a>
                        <hr/>
                    </Fragment>

                    <Fragment>
                        <a href="/gfl">
                            <h5>
                                GFL COMPANY LIMITED
                            </h5>
                        </a>
                        <hr/>
                    </Fragment>

                    <Fragment>
                        <a href="/mfl">
                            <h5>
                                MERCURY FINANCE LIMITED
                            </h5>
                        </a>
                        <hr/>
                    </Fragment>

                    <Fragment>
                        <a href="/ott">
                            <h5>
                                OTT TRADING LIMITED
                            </h5>
                        </a>
                        <hr/>
                    </Fragment>

                    <Fragment>
                        <a href="/tpl">
                            <h5>
                                TRARSTONE PTY LTD
                            </h5>
                        </a>
                        <hr/>
                    </Fragment>

                    <Fragment>
                        <a href="/yuanda">
                            <h5>
                                YUANDA INVESEMTN NZ LIMITED
                            </h5>
                        </a>
                        <hr/>
                    </Fragment>

                    <Fragment>
                        <a href="/cfg">
                            <h5>
                                CORPORATE FINANCIAL GROUP, INC.
                            </h5>
                        </a>
                        <hr/>
                    </Fragment>

                    <Fragment>
                        <a href="/ccfl">
                            <h5>
                                COLLINSON CROWDFUNDING LIMITED
                            </h5>
                        </a>
                        <hr/>
                    </Fragment>

                </Spin>
            </div>
        );
    }
}
