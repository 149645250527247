import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import ReduxThunk from "redux-thunk";
import { applicationReducer } from "@simpleryo/syw-application";
import * as reducers from "./reducers";

const rootReducer = combineReducers({
  ...reducers,
  application: applicationReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line no-underscore-dangle

export const configureStore = () =>
  createStore(rootReducer, composeEnhancers(applyMiddleware(ReduxThunk)));
