import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import NotFoundPage from "./NotFound";
import Landing from "../components/Landing";

export default class LandingPage extends Component {
  render() {
    const { match } = this.props;
    return (
      <Switch>
        <Route exact path={`${match.url}/:applyName`} component={Landing} />
        <Route component={NotFoundPage} />
      </Switch>
    );
  }
}

LandingPage.propTypes = {
  match: PropTypes.object.isRequired
};
