import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { Button, Spin, Alert } from "@simpleryo/collinson-uikit";
import R from "ramda";
import { APPLICATION } from "../constants/routes";
import DataSource from "../components/Application/DataSource";

export default class AdminPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      error: undefined,
      list: [],
      data: {},
      applyId: undefined
    };
  }

  componentDidMount() {
    DataSource.getApplicationList(true).then(
      res => {
        const list = [];
        R.mapObjIndexed((val, category) => {
          list.push(category);
          return val;
        })(res);
        this.setState({ isLoading: false, data: res, list });
      },
      () => {
        this.setState({
          isLoading: false,
          error: "Retrieve Applications Fail"
        });
      }
    );
  }

  createApp = event => {
    const {
      target: { name }
    } = event;
    this.setState({ isLoading: true });
    DataSource.createApplication(name, true).then(
      res => {
        const { code, content, msg } = res;
        if (code === 0) {
          this.setState({ isLoading: false, applyId: content.applyId });
        } else {
          this.setState({
            isLoading: false,
            error: msg || "Create Applications Fail"
          });
        }
      },
      () => {
        this.setState({
          isLoading: false,
          error: "Create Applications Fail"
        });
      }
    );
  };

  render() {
    const { isLoading, list, data, error, applyId } = this.state;
    if (applyId) {
      return <Redirect to={`${APPLICATION}/${applyId}/1/?preview=true`} />;
    }
    return (
      <div className="row">
        <h1 className="text-center">Preview Applications</h1>
        <hr />
        <Spin wrapperClassName="col-xs-12" spinning={isLoading}>
          {R.not(R.isEmpty(list)) &&
            R.map(category => (
              <Fragment key={category}>
                <h3>{category}</h3>
                <ul
                  className="list-unstyled list-inline"
                  onClick={this.createApp}
                >
                  {R.map(app => {
                    const { name } = app;
                    return (
                      <li key={name}>
                        <Button size="small" name={name}>
                          {name}
                        </Button>
                      </li>
                    );
                  })(data[category])}
                </ul>
                <hr />
              </Fragment>
            ))(list)}
        </Spin>

        {error && (
          <div className="col-xs-12">
            <Alert showIcon type="error" message={error} />
          </div>
        )}
      </div>
    );
  }
}
