import React from "react";
import PropTypes from "prop-types";
import { IntlProvider } from "react-intl";

const IntlProviderWrap = ({ children }) => {
  const { locale, messages } = window.SY_LOCALE;
  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

IntlProviderWrap.propTypes = {
  children: PropTypes.any.isRequired
};

export default IntlProviderWrap;
