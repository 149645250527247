import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import R from "ramda";
import { Markdown } from "@simpleryo/syw-uikit";
import { validateRules, validators } from "@simpleryo/syw-form";

const { RULE_REQUIRED } = validateRules;

const FieldTheme = ({
  children,
  id,
  className,
  rules,
  label,
  description,
  extra,
  hint,
  error
}) => {
  let errorType;
  let errorMessage;

  const style = R.pathOr({}, ["style"], extra);

  if (R.not(R.isNil(error))) {
    errorMessage = validators.getBreakRuleMessage(rules, error);
  }

  if (error) {
    errorType = error === RULE_REQUIRED ? "warning" : "error";
  }

  return (
    <div
      id={id}
      className={classNames("form-group", className, {
        "has-error": errorType === "error",
        "has-warning": errorType === "warning"
      })}
      style={style}
    >
      {label && (
        <label className="form-group__title">
          <span className="label">{label}</span>
        </label>
      )}

      {description && (
        <div className="small">
          <Markdown text={description} />
        </div>
      )}

      {children}

      {hint && <p className="small form-group__message">{hint}</p>}

      {errorMessage && (
        <p className="small form-group__message">{errorMessage}</p>
      )}
    </div>
  );
};

FieldTheme.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  className: PropTypes.string,
  rules: PropTypes.array,
  label: PropTypes.node,
  description: PropTypes.string,
  extra: PropTypes.object,
  hint: PropTypes.string,
  error: PropTypes.string,
  isOcr: PropTypes.bool
};

export default FieldTheme;
