import React from "react";
import {Router, Switch, Route} from "react-router-dom";
import {Provider} from "react-redux";
import history from "./utils/history";
import {configureStore} from "./redux/store";
import generateLayout, {LayoutName} from "./layouts/index";
import * as Routes from "./constants/routes";
import HomePage from "./views/Home";
import ApplicationPage from "./views/Application";
import LandingPage from "./views/Landing";
import DocumentsPage from "./views/Documents";
import AdminPage from "./views/Admin";
import NotFoundPage from "./views/NotFound";
import IntlProvider from "./i18n/IntlProvider";
import IndexPage from "./views/Index";
import IframeIndex from "./views/IframeIndex";
import Signature from "./views/Signature";
import SignatureSuccess from "./views/SignatureSuccess";

const store = configureStore();

const App = () => (
    <IntlProvider>
        <Provider store={store}>
            <Router history={history}>
                <Switch>
                    <Route
                        exact
                        path={Routes.APPLICATION_SIGNATURE}
                        component={generateLayout(LayoutName.MINIMAL, Signature)}
                    />
                    <Route
                        exact
                        path={Routes.APPLICATION_SIGNATURE_SUCCESS}
                        component={generateLayout(LayoutName.MINIMAL, SignatureSuccess)}
                    />
                    <Route
                        exact
                        path={Routes.IFRAME_INDEX}
                        component={generateLayout(LayoutName.MINIMAL, IframeIndex)}
                    />
                    <Route
                        exact
                        path={Routes.INDEX}
                        component={generateLayout(LayoutName.MINIMAL, IndexPage)}
                    />
                    <Route
                        exact
                        path={Routes.HOME}
                        component={generateLayout(LayoutName.MINIMAL, HomePage)}
                    />
                    <Route
                        path={Routes.APPLICATION}
                        component={generateLayout(LayoutName.MINIMAL, ApplicationPage)}
                    />
                    <Route
                        path={Routes.LANDING}
                        component={generateLayout(LayoutName.MINIMAL, LandingPage)}
                    />
                    <Route
                        exact
                        path={Routes.DOCUMENTS}
                        component={generateLayout(LayoutName.MINIMAL, DocumentsPage)}
                    />
                    <Route
                        exact
                        path={Routes.ADMIN}
                        component={generateLayout(LayoutName.MINIMAL, AdminPage)}
                    />
                    <Route component={generateLayout(LayoutName.MINIMAL, NotFoundPage)}/>
                </Switch>
            </Router>
        </Provider>
    </IntlProvider>
);

export default App;
