import React, {Component} from "react";
import PropTypes from "prop-types";
import {Modal, ContentCard, Alert, Spin} from "@simpleryo/collinson-uikit";
import R from "ramda";
import queryString from "query-string";
import {Redirect, withRouter} from "react-router-dom";
import SignaturePadWithHeader from "../components/SignaturePadWithHeader";
import {fetchStatusMap, tokenStatusMap} from "../constants/status";
import {APPLICATION_SIGNATURE_SUCCESS} from "../constants/routes";
import DataSource from "../components/Application/DataSource";

class Signature extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchStatus: fetchStatusMap.LOADING,
            tokenInfo: {fetchStatus: fetchStatusMap.LOADING, valid: true},
            uploadInfo: {fetchStatus: fetchStatusMap.LOADED, previewUrl: undefined, error: undefined}
        };
    };

    componentDidMount() {
        const {location} = this.props;
        const {token} = queryString.parse(location.search);
        DataSource.checkExternalToken(token).then(
            res => {
                const {code, content} = res.data;
                if (code === 0) {
                    this.setState({tokenInfo: {fetchStatus: fetchStatusMap.LOADED, valid: content}});
                } else {
                    this.setState({
                        tokenInfo: {fetchStatus: fetchStatusMap.LOADED, valid: false}
                    });
                }
            },
            () => {
                this.setState({
                    tokenInfo: {fetchStatus: fetchStatusMap.LOADED, valid: false}
                });
            }
        );
    }

    onUpload = file => {
        const {location} = this.props;
        const {fieldId, token} = queryString.parse(location.search);
        DataSource.signatureUploadExternal({
            fieldId,
            externalToken: token,
            file
        }).then(
            res => {
                const {code, content} = res.data;
                if (code === 0) {
                    this.setState({uploadInfo: {fetchStatus: fetchStatusMap.LOADED, previewUrl: content.url}});
                } else {
                    this.setState({
                        uploadInfo: {fetchStatus: fetchStatusMap.LOADED, previewUrl: undefined, error: "System Error"}
                    });
                }
            },
            () => {
                this.setState({
                    uploadInfo: {fetchStatus: fetchStatusMap.LOADED, previewUrl: undefined, error: "System Error"}
                });
            }
        );
    };

    onSubmit = () => {
        const {
            location: {search}
        } = this.props;
        const {uploadInfo} = this.state;
        const {fieldId, fieldName, token} = queryString.parse(search);
        DataSource.saveFormExternal({
            externalToken: token,
            fields: {
                [fieldName]: {
                    id: fieldId,
                    value: uploadInfo.previewUrl,
                    error: ""
                }
            }
        }).then(
            res => {
                const {code} = res.data;
                if (code === 0) {
                    this.setState({tokenInfo: {fetchStatus: fetchStatusMap.LOADED, valid: false}});
                }
            },
            () => {
                this.setState({
                    uploadInfo: {fetchStatus: fetchStatusMap.LOADED, previewUrl: undefined}
                });
            }
        );
    };

    onCancel = () => {
        this.setState({
            uploadInfo: {fetchStatus: fetchStatusMap.LOADED, previewUrl: undefined}
        });
    };

    render() {
        const {
            match: {
                params: {applyId}
            },
            location: {search}
        } = this.props;
        const {tokenInfo, uploadInfo} = this.state;
        if (!tokenInfo.valid) {
            return (
                <Redirect
                    push
                    to={R.replace(
                        /:applyId/g,
                        applyId,
                        `${APPLICATION_SIGNATURE_SUCCESS}`
                    )}
                />
            );
        }
        return (
            <Spin wrapperClassName="col-xs-12" spinning={tokenInfo.fetchStatus === fetchStatusMap.LOADING}>
                <SignaturePadWithHeader
                    onSubmit={this.onUpload}
                    title=""
                    fetchStatus={uploadInfo.fetchStatus}
                    error={uploadInfo.error}
                />
                <Modal
                    className="confirm-signature"
                    visible={!!uploadInfo.previewUrl}
                    okText="Submit 提交"
                    cancelText="Cancel 取消"
                    onOk={this.onSubmit}
                    onCancel={this.onCancel}
                >
                    <ContentCard size="middle" centerAlign>
                        <p className="padding-top-10">
                            Confirm Submit 确认提交
                        </p>
                        <img
                            src={uploadInfo.previewUrl}
                            alt="signature 签名"
                        />
                    </ContentCard>
                </Modal>
            </Spin>
        );
    }
}

Signature.propTypes = {
    match: PropTypes.object,
    location: PropTypes.object
};

export default withRouter(Signature);