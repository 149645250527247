import React, { Component } from "react";
import PropTypes from "prop-types";
import R from "ramda";
import CommonGroups from "./CommonGroups";
import EditableTabsGroups from "./EditableTabsGroups";

class ApplicationGroups extends Component {
  render() {
    const { groupsList, cardExtra = {} } = this.props;
    return R.addIndex(R.map)((each, index) => {
      const { type, list } = each;
      if (type === "editable-tabs") {
        return <EditableTabsGroups key={index} groupsList={list} />;
      }

      return (
        <CommonGroups key={index} groupsList={list} cardExtra={cardExtra} />
      );
    })(groupsList);
  }
}

ApplicationGroups.propTypes = {
  groupsList: PropTypes.array.isRequired,
  cardExtra: PropTypes.object
};

export default ApplicationGroups;
