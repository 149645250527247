import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import R from "ramda";
import { connectApplicationFields } from "@simpleryo/syw-application";
import {
  FieldTheme,
  SelectField,
  InputField,
  DatepickerField,
  RadioField,
  CheckboxField,
  SignatureField,
  UploadFilesField,
  FIELD_TYPE
} from "@simpleryo/syw-form";
import { Markdown } from "@simpleryo/collinson-uikit";
import { DATE_FORMAT, DIVIDED_MARK } from "../../constants/config";
import SharedLinkContainer from "../../containers/SharedLinkContainer";
import DataSource from "./DataSource";

class ApplicationFields extends PureComponent {
  render() {
    const {
      field,
      applyId,
      handleFieldChange,
      handleFieldActions
    } = this.props;
    let FieldComponent;
    let fieldParams;
    const {
      type,
      rules,
      label,
      description,
      hint,
      error,
      ...restProps
    } = field;
    const themeParams = {
      rules,
      label,
      description,
      hint,
      error
    };
    const defaultConfig = {
      rules,
      ...restProps,
      onChange: handleFieldChange,
      onActions: handleFieldActions
    };
    switch (type) {
      case FIELD_TYPE.INPUT_TEXT:
        FieldComponent = InputField;
        fieldParams = {
          ...defaultConfig
        };
        break;
      case FIELD_TYPE.SELECT:
        FieldComponent = SelectField;
        fieldParams = {
          ...defaultConfig
        };
        break;
      case FIELD_TYPE.DATEPICKER:
        FieldComponent = DatepickerField;
        fieldParams = {
          ...defaultConfig,
          locale: "en",
          dateFormat: DATE_FORMAT
        };
        break;
      case FIELD_TYPE.SELECT_COUNTRIES:
        FieldComponent = SelectField;
        fieldParams = {
          ...defaultConfig,
          showSearch: true
        };
        break;
      case FIELD_TYPE.RADIO:
        FieldComponent = RadioField;
        fieldParams = {
          ...defaultConfig
        };
        break;
      case FIELD_TYPE.RADIO_VERTICAL:
        FieldComponent = RadioField;
        fieldParams = {
          ...defaultConfig,
          isVertical: true
        };
        break;
      case FIELD_TYPE.TEXTAREA:
        FieldComponent = InputField;
        fieldParams = {
          ...defaultConfig,
          type: "textarea"
        };
        break;
      case FIELD_TYPE.CHECKBOX:
        FieldComponent = CheckboxField;
        fieldParams = {
          ...defaultConfig
        };
        break;
      case FIELD_TYPE.CHECKBOX_VERTICAL:
            FieldComponent = CheckboxField;
            fieldParams = {
                ...defaultConfig,
                isVertical: true
            };
            break;
      case FIELD_TYPE.SIGNATURE_PAD:
        FieldComponent = SignatureField;
        fieldParams = {
          ...defaultConfig,
          className: "preview-signature",
          signaturePad: {
            title: "Digital Signature 电子签名",
            desc:
              "Use the box below to enter your signature. If you’re using a touchscreen, you can sign directly. Otherwise, use the mouse on your computer." +
              "                请在以下方框内签名，触屏设备可直接手签，电脑设备可用鼠标签字",
            waterMarkTxt: "",
            cancelTxt: "Re-Sign 重新签名",
            submitTxt: "Submit 提交",
            submittingTxt: "Submitting 提交中",
            onSubmit: file =>
              DataSource.uploadSignature({
                fieldId: field.id,
                applyId,
                file
              }).then(
                response => {
                  const {
                    data: { code, content, msg }
                  } = response;
                  return {
                    data: code === 0 ? content.url : "",
                    error: code === 0 ? "" : msg
                  };
                },
                err => ({
                  error: R.pathOr(
                    "Unknown Error",
                    ["response", "statusText"],
                    err
                  )
                })
              )
          }
        };
        break;
      case FIELD_TYPE.UPLOAD_MULTI_FILES:
        FieldComponent = UploadFilesField;
        fieldParams = {
          ...defaultConfig,
          buttonType: "primary",
          dividedMark: DIVIDED_MARK,
          hint:
            "The file's format supports JPG, PNG, JPEG, PDF or any video types and the size is not less than 100KB or exceed 20MB.",
          uploadFailMsg: "Upload failed, please try again",
          uploadParams: {
            ...DataSource.uploadFiles({ applyId, fieldId: field.id }),
            listType: "picture",
            multiple: true
          }
        };
        break;
      case FIELD_TYPE.TEMPLATE:
        FieldComponent = ({ text = "" }) => (
          <div>
            <Markdown text={text} />
          </div>
        );
        fieldParams = {
          text: restProps.description
        };
        break;
      default:
    }

    if(type === FIELD_TYPE.SIGNATURE_PAD){
        return (
            <FieldTheme {...themeParams}>
                <FieldComponent {...fieldParams} />
                <SharedLinkContainer field={field} applyId={applyId} />
            </FieldTheme>
        );
    }

    if (FieldComponent) {
      return (
        <FieldTheme {...themeParams}>
          <FieldComponent {...fieldParams} />
        </FieldTheme>
      );
    }

    return null;
  }
}

ApplicationFields.propTypes = {
  field: PropTypes.object.isRequired,
  applyId: PropTypes.number.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  handleFieldActions: PropTypes.func.isRequired
};
export default connectApplicationFields(ApplicationFields);
