import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import R from "ramda";
import classnames from "classnames";
import queryString from "query-string";
import { connectApplication } from "@simpleryo/syw-application";
import { Spin, Alert } from "@simpleryo/collinson-uikit";
import { storageUtils } from "@simpleryo/syw-utils";
import DataSource from "./DataSource";
import ApplicationSteps from "./ApplicationSteps";

class Application extends Component {
  render() {
    const {
      location: { search },
      injectedProps: { isLoading, error },
      subTitle,
      title
    } = this.props;
    const hideHeader = R.compose(
      R.equals("true"),
      R.path(["hide_header"]),
      queryString.parse
    )(search);
      const token = R.compose(
          R.path(["token"]),
          queryString.parse
      )(search);
      storageUtils.setSessionStorage("Token", token);
      return (
      <div
        className={classnames("row application", {
          "hide-header": hideHeader
        })}
      >
        <div className="application__headline text-center">
          <h5>{subTitle}</h5>
          <h3>{title}</h3>
        </div>

        <div className="application__content text-center">
          <Spin spinning={isLoading}>{title && <ApplicationSteps />}</Spin>
        </div>

        {error && <Alert message={error} type="error" showIcon />}
      </div>
    );
  }
}

Application.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  injectedProps: PropTypes.object,
  subTitle: PropTypes.string,
  title: PropTypes.string
};

const mapStateToProps = ({ application = {} }) => ({
  subTitle: application.subTitle,
  title: application.title
});

export default R.compose(
  connectApplication(DataSource),
  connect(mapStateToProps)
)(Application);
