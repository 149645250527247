export const IFRAME_INDEX = "/";
export const INDEX = "/7992f99b-b2d2-4747-9c4b-42e0f12dd493";
export const HOME = "/forms";
export const APPLICATION = "/application";
export const LANDING = "/landing";
export const DOCUMENTS = "/documents";
export const ADMIN = "/admin";
export const APPLICATION_SIGNATURE = "/signature/:applyId";
export const APPLICATION_SIGNATURE_SUCCESS = "/signature/:applyId/success";

