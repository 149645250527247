import sampleSize from "lodash/sampleSize";
import R from "ramda";
import QueryString from "query-string";
import { localeUtils } from "@simpleryo/syw-utils";
import { validateRules } from "@simpleryo/syw-form";
import { DIVIDED_MARK } from "../constants/config";
import thumbnailDOC from "../../assets/imgs/thumbnail-doc.jpg";
import { formatMsg } from "../i18n/FormattedMessage";

export const mapIndexed = R.addIndex(R.map);

export const randomInt = (min, max) => {
  const minInt = Math.ceil(min);
  const maxInt = Math.floor(max);
  return Math.floor(Math.random() * (maxInt - minInt)) + minInt; // The maximum is exclusive and the minimum is inclusive
};

export const toggleBodyScrollable = isScrollable => {
  const htmlNode = document.getElementsByTagName("html")[0];
  if (R.not(isScrollable)) {
    htmlNode.classList.add("no-scroll");
  } else {
    htmlNode.classList.remove("no-scroll");
  }
};

export const toggleRootAppDisplay = isDisplay => {
  const htmlNode = document.getElementsByTagName("html")[0];
  if (R.not(isDisplay)) {
    htmlNode.classList.add("hide-root");
  } else {
    htmlNode.classList.remove("hide-root");
  }
};

export const generateVerifyCode = (length = 6) => {
  const chars =
    "abcdefghijklmnopqrstufwxyzABCDEFGHIJKLMNOPQRSTUFWXYZ1234567890";
  return sampleSize(chars, length).join("");
};

export const loadBase64Image = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const getCurrentScrollTop = () =>
  window.pageYOffset ||
  document.body.scrollTop ||
  document.documentElement.scrollTop;

export const scrollToTop = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

// from http://detectmobilebrowsers.com/mobile
export const isMobile = () => {
  const browserInfo = navigator.userAgent || navigator.vendor || window.opera;
  const prefix = browserInfo.substr(0, 4);
  /*eslint-disable*/
  return (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      browserInfo
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      prefix
    )
  );
  /* eslint-enable */
};

export const handleFilterOption = (input, option) => {
  const results = option.props.children.props.value;
  return results.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

export const emailSecreted = email => {
  if (!email) return "";

  const lengthToShow = 2;
  const index = email.indexOf("@");
  if (index < lengthToShow + 1) {
    return `${R.join("", R.repeat("*", index))}${email.substring(index)}`;
  }

  const secret = email.substring(lengthToShow, index).replace(/./g, "*");

  return `${email.substring(0, lengthToShow)}${secret}${email.substring(
    index
  )}`;
};

/**
 * Remove the zeros in front of the phone number.
 * The regExp is the same with the backend service.
 * @param  {String} number The phone number needs to be trimmed.
 * @return {String}
 */
export const trimPhoneNumber = (number = "") =>
  R.replace(/^0+(?!$)/, "", number);

/**
 * handle the response for downloading zip from backend.
 * @param  {Object} response [include attributes: code{number}, msg{String}, content{String}]
 * @return {String}          [error message, "" means correct]
 */
export const handleDownloadZip = response => {
  const { code, msg, content } = response;
  let error = "";
  if (code === 0) {
    if (document.getElementById("download")) {
      document.getElementById("download").remove();
    }
    const link = document.createElement("a");
    link.setAttribute("id", "download");
    link.setAttribute("download", "documents.zip");
    link.href = window.URL.createObjectURL(content);
    document.body.appendChild(link);
    link.click();
  } else if (code === 206) {
    error = formatMsg("VISA_FORM_DOWNLOAD_ERROR_PENDING");
  } else {
    error = msg || formatMsg("VISA_FORM_DOWNLOAD_ERROR");
  }
  return error;
};

/**
 * [HACK] Make up OSS file path for passing the antd isImageUrl validation.
 * @param  {String} path [oss file path]
 * @return {String} newURl [new newURL with an extra param]
 */
export const OssFilePathMakeUp = path => {
  if (!path) {
    return false;
  }
  const { url } = QueryString.parseUrl(path);
  const name = R.compose(R.last, R.split("/"))(url);
  const extension = R.compose(R.toLower, R.last, R.split("."))(name);
  return `${path}&ext=.${extension}`;
};

/**
 * Generate fileList function for Antd Upload component
 * @param  {String} oss file path divided by the mark (||)
 * @return {Array} formatted fileList as param of Antd Upload Component
 */
export const generatePictureFileList = oss => {
  if (!oss) {
    return [];
  }
  return R.compose(
    R.reduce((acc, cur) => {
      const { url } = QueryString.parseUrl(cur);
      const name = R.compose(R.last, R.split("/"))(url);
      const extension = R.compose(R.toLower, R.last, R.split("."))(name);
      const thumbUrl = R.contains(
        extension,
        validateRules.NON_IMAGE_FILE_TYPE_LIST
      )
        ? thumbnailDOC
        : `${cur}&ext=.${extension}`;
      return [
        ...acc,
        {
          uid: name,
          name,
          status: "done",
          url: cur,
          thumbUrl
        }
      ];
    }, []),
    R.split(DIVIDED_MARK)
  )(oss);
};

/**
 * Check whether debug mode enable or not.
 * @return {String}
 */
export const checkDebugMode = () => {
  const { debug } = QueryString.parse(window.location.search);
  return debug;
};

/**
 * [generate name by locale]
 * @param  {String} givenName  [given name]
 * @param  {String} middleName [middle name]
 * @param  {String} familyName [family name]
 * @return {String}            [full name]
 */
export const genNameByLocale = ({
  givenName = "",
  middleName = "",
  familyName = ""
}) => {
  if (localeUtils.getLocale() === localeUtils.LANG_ZH) {
    return `${familyName} ${middleName} ${givenName}`;
  }
  return `${givenName} ${middleName} ${familyName}`;
};
