import React, { Component } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import R from "ramda";
import { ContentCard, Badge, Button } from "@simpleryo/collinson-uikit";

export default class DocumentsPage extends Component {
  render() {
    const {
      location: {
        search,
        state: { submit = {} }
      }
    } = this.props;
    const returnUrl = R.compose(
      R.path(["return_url"]),
      queryString.parse
    )(search);
    const { content = [], error, success } = submit;
    return (
      <div className="row">
        <div className="col-xs-12">
          <ContentCard centerAlign>
            {success && !returnUrl && (
              <div>
                <Badge
                  size={80}
                  icon="icon-check"
                  customClass="white bg--green margin-top-60"
                />
                <p>{"Submit Success"}</p>
              </div>
            )}
          {success && returnUrl && (
          <div>
          <Badge
              size={80}
              icon="icon-check"
              customClass="white bg--green margin-top-60"
                  />
                  <p>{"Application Form Completed"}</p>
                  </div>
          )}
            {error && (
              <div>
                <Badge
                  size={80}
                  icon="icon-close"
                  customClass="white bg--red margin-top-60"
                />
                <p>{"ERROR"}</p>
              </div>
            )}
          </ContentCard>
        </div>
        <div className="col-xs-12">
          <hr />
        </div>
        {R.not(R.isEmpty(content)) &&
          !returnUrl && (
            <div className="col-xs-12">
              <ul>
                {R.addIndex(R.map)((item, index) => (
                  <li key={index} className="padding-bottom-10">
                    <a href={item} rel="noopener noreferrer" target="_blank">
                      {"Download Document"}
                    </a>
                  </li>
                ))(content)}
              </ul>
            </div>
          )}
      {returnUrl && (
      <div className="col-xs-12 text-center">
          <p style={{
              fontWeight: "bold",
              fontSize: "19px"
          }}>{"Please click X on the top right to close current page and click “Invest Now” to complete your investment."}</p>
          </div>
      )}
        <div className="col-xs-12">
          <hr />
        </div>
      </div>
    );
  }
}

DocumentsPage.propTypes = {
  location: PropTypes.object.isRequired
};
