import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Badge = ({
  type = "default",
  size = 36,
  icon,
  customClass,
  status = "default",
  children
}) => {
  if (type === "number") {
    return (
      <div className={`badge ${customClass} badge--numbersize-${size}`}>
        {children}
      </div>
    );
  } else if (type === "status") {
    return (
      <div
        className={`status-badge status-badge--${status} status-badge--size-${size}`}
      >
        {icon ? <i className={icon} /> : children}
      </div>
    );
  }
  return (
    <div
      className={classNames("badge", {
        [customClass]: customClass,
        [`badge--size-${size}`]: size
      })}
    >
      <i className={icon} />
    </div>
  );
};

Badge.propTypes = {
  type: PropTypes.string,
  size: PropTypes.number,
  icon: PropTypes.string,
  customClass: PropTypes.string,
  status: PropTypes.string,
  children: PropTypes.node
};

export default Badge;
