import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import R from "ramda";
import { Collapse, Markdown } from "@simpleryo/collinson-uikit";
import { connectApplicationCards } from "@simpleryo/syw-application";
import ApplicationGroups from "./ApplicationGroups";

class ApplicationCards extends Component {
  handleOpenCards = list => {
    const {
      injectedProps: { handleOpenMultiCards }
    } = this.props;
    handleOpenMultiCards(list);
  };

  render() {
    const { cardsList, cards } = this.props;
    return (
      <Collapse
        bordered={false}
        activeKey={cardsList}
        onChange={this.handleOpenCards}
      >
        {R.map(name => {
          const card = cards[name];
          return (
            <Collapse.Panel
              header={
                <div>
                  <h5>
                    <strong>{card.title}</strong>
                  </h5>
                  {card.description && <Markdown text={card.description} />}
                </div>
              }
              key={card.name}
              className={card.status}
            >
              <ApplicationGroups
                groupsList={card.groupsList}
                cardExtra={card.extra}
              />
            </Collapse.Panel>
          );
        })(cardsList)}
      </Collapse>
    );
  }
}

ApplicationCards.propTypes = {
  cardsList: PropTypes.array.isRequired,
  cards: PropTypes.object,
  openedCards: PropTypes.array,
  injectedProps: PropTypes.object
};

const mapStateToProps = ({ application }) => ({
  cards: application.cards,
  openedCards: application.openedCards
});

export default R.compose(
  connectApplicationCards,
  connect(mapStateToProps)
)(ApplicationCards);
