import {createClient} from "contentful";
import axios from "axios";
import queryString from "query-string";
import R from "ramda";
import {storageUtils} from "@simpleryo/syw-utils";
import convertToAppListFromContentful from "../../utils/convertToAppListFromContentful";
import convertToAppList from "../../utils/convertToAppList";

let client;
let authorized;
const getClient = () => {
    if (authorized) {
        return Promise.resolve(client);
    }
    client = createClient({
        space: "h0lk7ppyvy9h",
        accessToken:
            "c222d7ac1d83a151caf721a7a490568a48eae0c669545188c38f6d5c0ad250c5",
        host: "cdn.contentful.com"
    });
    return client.getSpace().then(() => {
        authorized = true;
        return client;
    });
};

const HOST = {
    production:
    R.path(["CLS_SERVER_CONFIG", "API_HOST"], window) ||
    "http://api.kyconline.co.nz/",
    development: "http://api.kyconline.co.nz/",
    mock: `${window.location.origin}/`
};

const host = HOST[process.env.NODE_ENV];

const defaultConfig = {
    baseURL: host,
    headers: {
        "accept-language": "en"
    }
};

const oauthConfig = R.mergeDeepRight(defaultConfig, {
    method: "post",
    url: "/oauth/token",
    auth: {
        username: "simpleryo",
        password: "simpleryo"
    },
    headers: {
        "Content-Type": "application/x-www-form-urlencoded"
    }
});

const authApiConfig = () => {
    const auth = storageUtils.getSessionStorage("Auth") || {};
    return R.mergeDeepRight(defaultConfig, {
        headers: {
            Authorization: `${auth.token_type} ${auth.access_token}`
        }
    });
};

const convertToFormData = args => {
    const params = new FormData();
    R.mapObjIndexed((val, key) => params.append(key, val), args);
    return params;
};

const AuthSource = {
    getUserAccount: () =>
        axios({
            ...defaultConfig,
            url: "/onboard/getNewUser"
        }),
    getUserToken: username =>
        axios({
            ...oauthConfig,
            url: "/oauth/token",
            data: convertToFormData({
                grant_type: "password",
                username
            })
        }),
    refreshUserToken: ({refresh_token, jti}) =>
        axios({
            ...oauthConfig,
            url: "oauth/token",
            data: convertToFormData({
                grant_type: "refresh_token",
                refresh_token,
                jti
            })
        })
};

const getAuth = () =>
    new Promise((resolve, reject) => {
        const auth = storageUtils.getSessionStorage("Auth") || {};
        if (R.not(R.isEmpty(auth))) {
            resolve(auth);
        } else {
            AuthSource.getUserAccount().then(
                user => {
                    if (user.status === 200 && user.data.code === 0) {
                        AuthSource.getUserToken(user.data.content).then(token => {
                            if (token.status === 200) {
                                storageUtils.setSessionStorage("Auth", token.data);
                                resolve(token.data);
                            }
                        });
                    }
                },
                err => reject(err)
            );
        }
    });

const getEditAuth = () =>
    new Promise((resolve, reject) => {
        const auth = storageUtils.getSessionStorage("Auth") || {};
        const userToken = storageUtils.getSessionStorage("Token") || {};
        if (R.not(R.isEmpty(userToken))) {
            AuthSource.getUserToken(userToken).then(
                token => {
                    if (token.error) {
                        reject();
                    } else if (token.status === 200) {
                        storageUtils.setSessionStorage("Auth", token.data);
                        resolve(token.data);
                    }
                },
                err => {
                    reject(err);
                }
            );
        } else if (R.not(R.isEmpty(auth))) {
            resolve(auth);
        } else {
            resolve(auth);
        }
    });

const retrieveContentfulAppList = () =>
    new Promise((resolve, reject) => {
        getClient().then(instance =>
            instance.getEntries({content_type: "applications"}).then(
                data => {
                    const appList = convertToAppListFromContentful(data.items);
                    resolve(appList);
                },
                err => reject(err)
            )
        );
    });

const retrieveAppList = () =>
    axios({
        ...defaultConfig,
        url: "/visa/all"
    }).then(
        response => {
            const {
                data: {code, content = []}
            } = response;
            if (code === 0) {
                const {visaInfoList = []} = content[0];
                return convertToAppList(visaInfoList);
            }
            return {};
        },
        error => error
    );

const handleError = error => {
    const {
        response: {status, data}
    } = error;
    if (status === 401 && data.error === "invalid_token") {
        // get application data fail cause access_token is expired.
        const {refresh_token, jti} = storageUtils.getSessionStorage("Auth");
        return AuthSource.refreshUserToken({refresh_token, jti}).then(
            res => {
                storageUtils.setSessionStorage("Auth", res.data);
                window.location.reload();
            },
            err => {
                // refresh token fail cause refresh_token is expired.
                window.sessionStorage.clear();
                window.location.reload();
                console.log("refresh token error -->", err);
            }
        );
    }
    return error;
};

const createContentfulApp = name =>
    new Promise((resolve, reject) => {
        getClient().then(instance =>
            instance
                .getEntries({
                    content_type: "applications",
                    "fields.name": name,
                    include: 0
                })
                .then(
                    data => {
                        const {items = []} = data;
                        const {sys = {}} = items[0];
                        if (sys.id) {
                            resolve({
                                code: 0,
                                content: {applyId: sys.id},
                                msg: "success"
                            });
                        } else {
                            resolve({
                                code: 1,
                                msg: "retrieve contentful application fail"
                            });
                        }
                    },
                    err => reject(err)
                )
        );
    });

const createApp = name =>
    axios({
        ...authApiConfig(),
        url: `/visaapply/visa?visaName=${name}`
    }).then(
        response => {
            const {data} = response;
            return data;
        },
        error => handleError(error)
    );

const retrieveContentfulApp = applyId =>
    new Promise((resolve, reject) => {
        getClient().then(instance =>
            instance
                .getEntries({
                    "sys.id": applyId,
                    include: 10
                })
                .then(data => resolve(data), err => reject(err))
        );
    });

const retrieveApp = applyId =>
    axios({
        ...authApiConfig(),
        url: `/visaapply/visa?applyId=${applyId}`
    }).then(
        response => response.data,
        error => {
            storageUtils.setSessionStorage("Auth", "");
            storageUtils.setSessionStorage("Token", "");
            handleError(error);
        }
    );

const DataSource = {
    getCountries: () =>
        axios({
            ...defaultConfig,
            url: "/visa/countries"
        }),
    getFieldOptions: () =>
        axios({
            ...defaultConfig,
            url: "/fieldOptions"
        }),
    getApplicationList: (fromContentful = false) => {
        if (fromContentful) {
            return retrieveContentfulAppList();
        }
        return retrieveAppList();
    },
    createApplication: (name, fromContentful = false) => {
        if (fromContentful) {
            return createContentfulApp(name);
        }
        return getAuth().then(
            () => createApp(name),
            err => console.error("get auth info fail:", err)
        );
    },
    getApplication: (applyId, fromContentful = false) => {
        if (fromContentful) {
            return retrieveContentfulApp(applyId);
        }
        return getEditAuth().then(
            () => retrieveApp(applyId),
            err => {
                storageUtils.setSessionStorage("Auth", "");
                storageUtils.setSessionStorage("Token", "");
                console.error("get application fail:", err);
            }
        );
    },
    saveCards: data => {
        return axios({
            ...authApiConfig(),
            method: "post",
            url: "/visaapply/save",
            data
        }).then(
            response => {
                if (response.data.code != 0) {
                    if (confirm("Please check for missing information or errors on this step. 当前步骤未填写完整，请检查。")) {
                        var urlArray = location.pathname.split("/");
                        if (urlArray[urlArray.length - 1] != "1") {
                            history.back();
                        }
                        return response;
                    }
                }
            }
        );
    },
    getExternalToken: applyId =>
        axios({
            ...authApiConfig(),
            method: "get",
            url: `/visaapply/genExternalToken/${applyId}`
        }),
    checkExternalToken: token =>
        axios({
            ...defaultConfig,
            method: "get",
            url: `/visaapply/checkExternalToken?token=${token}`
        }),
    signatureUploadExternal: data =>
        axios({
            ...defaultConfig,
            url: "/file/uploadExternal",
            method: "post",
            data: convertToFormData({
                ...data,
                fileTypeId: 1,
                subTypeId: 0
            })
        }),
    saveFormExternal: data =>
        axios({
            ...defaultConfig,
            url: "/visaapply/saveExternal",
            method: "post",
            data
        }),
    sendSignatureEmail: data =>
        axios({
            ...authApiConfig(),
            method: "post",
            url: "/utilities/sendSignatureEmail",
            data
        }),
    uploadSignature: data =>
        axios({
            ...authApiConfig(),
            url: "/file/upload",
            method: "post",
            data: convertToFormData({
                ...data,
                fileTypeId: 1,
                subTypeId: 0
            })
        }),
    uploadFiles: data => ({
        ...authApiConfig(),
        name: "file",
        action: `${host}file/upload`,
        data: {
            ...data,
            fileTypeId: 1,
            subTypeId: 0
        }
    }),
    submitApplication: applyId => {
        const params = R.compose(
            queryString.stringify,
            R.mergeDeepLeft({applyId}),
            queryString.parse
        )(window.location.search);

        return axios({
            ...authApiConfig(),
            method: "post",
            url: `/visaapply/submit?${params}`
        }).then(
            response => {
                if (response.data.code != 0) {
                    if (confirm("Submit Failed : " + JSON.stringify(response.data.content))) {
                        window.location.reload();
                    } else {
                        window.location.reload();
                    }
                }
                return response;
            },
            error => handleError(error)
        );
    }
};

export default DataSource;
