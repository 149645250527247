import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import { appConfig } from "@simpleryo/syw-application";
import NotFoundPage from "./NotFound";
import Application from "../components/Application";

export default class ApplicationPage extends Component {
  render() {
    const { match } = this.props;
    return (
      <Switch>
        <Route
          exact
          path={`${match.url}${appConfig.APP_ROUTE_PARAM_PATTERN}`}
          component={Application}
        />
        <Route component={NotFoundPage} />
      </Switch>
    );
  }
}

ApplicationPage.propTypes = {
  match: PropTypes.object.isRequired
};
