import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connectCommonGroups } from "@simpleryo/syw-application";
import ApplicationFields from "../ApplicationFields";

class CommonGroups extends Component {
  render() {
    const {
      injectedProps: { group, isHide }
    } = this.props;
    return (
      <div
        className={classNames("application-group", {
          hidden: isHide
        })}
      >
        <div className="row">
          <ApplicationFields fieldsList={group.fieldsList} />
        </div>
      </div>
    );
  }
}

CommonGroups.propTypes = {
  injectedProps: PropTypes.shape({
    group: PropTypes.object.isRequired,
    isHide: PropTypes.bool.isRequired
  })
};

export default connectCommonGroups(CommonGroups);
